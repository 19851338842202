
import React from 'react'
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Main from './Components/Main/Main';

const Home = () => {
  return (
    <>
        <Header/>
        <Main/>
        <Footer/>
    </>
  )
}

export default Home;