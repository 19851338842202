import React from "react";
import AppRoutes from "./Routes/AppRoutes";
import './App.css'

const App =() =>{

    return(
        <>
            <AppRoutes/>
        </>
    )
}

export default App;